

<!-- <app-header></app-header>
<main>
    <router-outlet></router-outlet>
</main>
<app-footer></app-footer> -->
<!-- <app-header  [item]='navURL'></app-header> -->
<main>
    <router-outlet></router-outlet>
</main>
<!-- <app-footer></app-footer> -->
