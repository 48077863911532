import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import {AppService} from './app.service';
import { LocalStorageService } from './Services/auth-service/local-storage.service';
import {Router,NavigationStart,NavigationEnd} from '@angular/router';
import { filter } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { App as CapacitorApp } from '@capacitor/app';




@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'inq-sam';
  currentUrl : any ="home";
  navURL : any ='/'
  c:any;
  profileDetails:any;
  disableButton: boolean = false;
  message:any;
  runApp:any=true;
  appDetails:any;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };

 
  socialMediaData:any = [];
  taxratedId = {
    rateId:0,
    productTaxId:0,
    handlingTaxId:0,
    shippingTaxId:0
  }

  ordertaxdetails = {
    rateId:0,
    productTaxId:0,
    handlingTaxId:0,
    shippingTaxId:0,
    productTaxClass:"",
    handlingTaxClass:"",
    shippingTaxClass:""
  }

  shippingCarriers = {
    shipping_carrier_id : 0,
    shipping_carrier_name : "",
    api_integration_id : 0,
    api_integration_name : ""
  }

  pickdropcall:boolean = false;

  @HostListener('window:scroll', ['$event']) // for window scroll events
  scrollFunction() {
    var mybutton = document.getElementById("myBtn");
    if (document.body.scrollTop > 1200 || document.documentElement.scrollTop > 1200) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
    
  }

  constructor(
    public appService : AppService,
    private router : Router,
    private localService : LocalStorageService,
    private localStorage: LocalStorageService,
    private deviceService: DeviceDetectorService,
  )
  {
   
  }
  
  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHander($event: any) {
  // //  this.checkoutLocalstorageService.clearMasterdata();
  // }

  ngOnInit()
  {
    
  }

}
